import React from 'react';
import './WhyChoose.css'; // Import your CSS file

const WhyChoose = () => {
  return (
    <section className="solutions-section">
    <h2 className="hcolor">WHY CHOOSE OUR SOFTWARE DEVELOPMENT SERVICES?</h2>
    <hr className="hr-line" />
    <div className="client-section">
        <div className="client-item">
            <h3>Client-Centric Focus</h3>
            <p>Our journey begins and ends with you – our valued client. With an unwavering commitment to a client-centric approach, we customize our solutions to cater to your distinct needs. Your satisfaction drives every facet of our process.</p>
        </div>
        <div className="client-item">
            <h3>User-Centered Design</h3>
            <p>By putting ourselves in the shoes of your audience, we craft visually captivating interfaces that provide intuitive and delightful interactions, fostering a lasting connection between users and your product.</p>
        </div>
        <div className="client-item">
            <h3>Innovative Expertise</h3>
            <p>Innovation is ingrained in our DNA. Our team's unwavering dedication to creative solutions ensures your projects are infused with the latest trends and groundbreaking ideas.</p>
        </div>
        <div className="client-item">
            <h3>Collaborative Partnership</h3>
            <p>We see our clients as partners and embrace a collaborative approach. Your insights and feedback are invaluable, guiding us every step of the way to ensure the final product reflects our combined vision and expertise.</p>
        </div>
        <div className="client-item">
            <h3>Punctual Delivery</h3>
            <p>Our resolute commitment to on-time delivery ensures your projects are completed within the agreed-upon schedules, maintaining the highest quality standards throughout the process.</p>
        </div>
        <div className="client-item">
            <h3>Comprehensive Support</h3>
            <p>Our commitment extends beyond development. We provide continuous updates, maintenance, and assistance, ensuring your software remains at its best long after deployment.</p>
        </div>
    </div>

  </section>
  
   
  );
};

export default WhyChoose;
