import React from 'react';
import './InduSolution.css'; // Import your CSS file

const InduSolution = () => {
  return (
      
    <section className="process-section">
        <h2>TAILORED INDUSTRY SOLUTIONS</h2>
        <hr className="hr-line" />
      <div className="process-content">
        <div className="process-text">
          <ul>
            <li>
              <h4>Crafting Durable and Streamlined Technology Solutions</h4>
              <p>
              As a provider of industry-specific software solutions, we meticulously customize each solution to precisely meet the unique needs of each company. Our services are dedicated to propelling businesses forward and guiding them towards unparalleled success.

Backed by a team renowned for their technical expertise, domain knowledge, and years of experience, we take pride in offering comprehensive end-to-end technology solutions.

              </p>
            </li>
          </ul>
        </div>
        <div className="process-image">
          <img src="/solu1.png" alt="Process" />
        </div>
      </div>
    </section>
  );
};

export default InduSolution;
