import React from 'react';
import './Footer.css'
const Footer = () => (
  <footer className="footer">
    <div className="left">Copyright © 2023 ServiPro - All Rights Reserved.</div>
    <div className="right">Powered by ServiPro</div>
  </footer>
);

export default Footer;
